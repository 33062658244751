const booleanRenderer = v => v === true ? 'Да' : 'Нет'
// const intRenderer = v => {
//     const fl = Math.floor(v)

//     if (isNaN(fl)) {
//         return ''
//     }

//     return fl
// }

const kkRenderer = value => {
    value = Math.floor(value)

    if (isNaN(value)) {
        return ''
    }

    if (value < 1000) {
        return Math.trunc(value)
    }

    let suffix = ''

    while (value >= 1000) {
        value /= 1000
        suffix += 'k'
    }

    return `${Math.trunc(value)} ${suffix}`
}

const dateRenderer = value => {
    if (value < 1) {
        return '-'
    }

    return new Date(value * 1000).toLocaleString()
}

export default {
    "player": {
        "name": "Игрок",
        "sub": {
            "player.name": {
                "name": "имя",
                "type": "text",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "compOnly": ["equal", "notequal", "isnull", "isnotnull"],
                "width": 120
            },
            "player.alliance": {
                "name": "альянс",
                "type": "text",
                "searchable": true,
                "sortable": false,
                "checked": true,
                "compOnly": ["equal", "notequal", "isnull", "isnotnull"],
                "width": 102
            },
            "player.rank": {
                "name": "ранг",
                "type": "number",
                "searchable": true,
                "sortable": false,
                "checked": true,
                "width": 60,
            },
            "player.status.active": {
                "name": "статус - активный",
                "columnName": "активный",
                "type": "boolean",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "renderFn": booleanRenderer,
                "compOnly": ["equal", "notequal"],
                "width": 79,
            },
            "player.status.vacation": {
                "name": "статус - в отпуске",
                "columnName": "в отпуске",
                "type": "boolean",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "renderFn": booleanRenderer,
                "compOnly": ["equal", "notequal"],
                "width": 78,
            },
            "player.status.blocked": {
                "name": "статус - заблокирован",
                "columnName": "заблокирован",
                "type": "boolean",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "renderFn": booleanRenderer,
                "compOnly": ["equal", "notequal"],
                "width": 107,
            },
            "player.status.rookie": {
                "name": "статус - новичок",
                "columnName": "новичок",
                "type": "boolean",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "renderFn": booleanRenderer,
                "compOnly": ["equal", "notequal"],
                "width": 73,
            },
            "player.status.weekago": {
                "name": "статус - не заходил неделю",
                "columnName": "отсут. 7 дн.",
                "type": "boolean",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "renderFn": booleanRenderer,
                "compOnly": ["equal", "notequal"],
                "width": 73,
            },
        }
    },
    "planet": {
        "name": "Планета",
        "sub": {
            "planet.name": {
                "name": "название",
                "type": "text",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "compOnly": ["equal", "notequal", "isnull", "isnotnull"],
                "width": 89,
            },
            "planet.path": {
                "name": "корды",
                "columnName": "корды",
                "type": "number",
                "searchable": false,
                "sortable": false,
                "checked": true,
                "disabled": false,
                "width": 73,
            },
            "planet.cluster": {
                "name": "кластер",
                "columnName": "КЛ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "compOnly": ["equal"],
                "searchOnce": true,
                "required": true,
                "disabled": true,
                "width": 38,
            },
            "planet.star": {
                "name": "звездная система",
                "columnName": "ЗС",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "required": true,
                "disabled": true,
                "width": 41,
            },
            "planet.planet": {
                "name": "планета",
                "columnName": "ПЛ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "required": true,
                "disabled": true,
                "width": 40,
            },
        }
    },
    "resources": {
        "name": "Ресурсы",
        "sub": {
            "resources.titanium": {
                "name": "титан",
                "columnName": "Ti",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "renderFn": kkRenderer,
                "width": 74,
            },
            "resources.silicon": {
                "name": "кремний",
                "columnName": "Si",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "renderFn": kkRenderer,
                "width": 67,
            },
            "resources.antimatter": {
                "name": "антиматерия",
                "columnName": "An",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "renderFn": kkRenderer,
                "width": 67,
            },
            // "resources.shuttle": {
            //     "name": "шатл",
            //     "type": "number",
            //     "searchable": true,
            //     "sortable": true,
            //     "checked": true,
            //     "rendererFn": intRenderer
            // },
        }
    },
    "debris": {
        "name": "Обломки",
        "sub": {
            "debris.titanium": {
                "name": "титан",
                "columnName": "Ti",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "renderFn": kkRenderer,
                "width": 67,
            },
            "debris.silicon": {
                "name": "кремний",
                "columnName": "Si",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "renderFn": kkRenderer,
                "width": 61,
            },
        }
    },
    "first": {
        "name": "Первый залп",
        "sub": {
            "first.defence": {
                "name": "оборона",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 74,
            },
            "first.fleet": {
                "name": "корабли",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 72,
            },
        }
    },
    "building": {
        "name": "Строения",
        "sub": {
            "building.titanium_mine": {
                "name": "титановая шахта",
                "columnName": "ТШ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.collider": {
                "name": "коллайдер",
                "columnName": "КЛД",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.titanium_silo": {
                "name": "склад титана",
                "columnName": "СТ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.robofactory": {
                "name": "робофабрика",
                "columnName": "РФ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.nanofactory": {
                "name": "нанофабрика",
                "columnName": "НФ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.annihilation_reactor": {
                "name": "аннигиляционный реактор",
                "columnName": "АР",
                "type": "number",
                "sortable": true,
                "searchable": true,
                "checked": true,
                "width": 40,
            },
            "building.dock": {
                "name": "док",
                "columnName": "ДОК",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.teleport": {
                "name": "телепорт",
                "columnName": "ТП",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
            },
            "building.science_center": {
                "name": "научный центр",
                "columnName": "НЦ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.filling_base": {
                "name": "заправочная база",
                "columnName": "ЗБ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.antimatter_storage": {
                "name": "склад антиматерии",
                "columnName": "СА",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.silicon_silo": {
                "name": "склад кремния",
                "columnName": "СК",
                "type": "number",
                "sortable": true,
                "searchable": true,
                "checked": true,
                "width": 40,
            },
            "building.silicon_mine": {
                "name": "кремниевая шахта",
                "columnName": "КШ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.neutrino_power_station": {
                "name": "нейтринная электростанция",
                "columnName": "НЭ",
                "type": "number",
                "sortable": true,
                "searchable": true,
                "checked": true,
                "width": 40,
            },
            "building.planetary_energy_center": {
                "name": "планетарный энергоузел",
                "columnName": "ПЭ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "building.nanotechnology_center": {
                "name": "центр нанотехнологий",
                "columnName": "ЦН",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
        }
    },
    "technology": {
        "name": "Технологии",
        "sub": {
            "technology.planet_scanning": {
                "name": "планетарное сканирование",
                "columnName": "ПС",
                "type": "number",
                "sortable": true,
                "searchable": true,
                "checked": true,
                "width": 40,
            },
            "technology.navigation": {
                "name": "навигация",
                "columnName": "НВ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.armament": {
                "name": "вооружения",
                "columnName": "ВЖ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.ship_protection": {
                "name": "защита кораблей",
                "columnName": "ЗК",
                "type": "number",
                "sortable": true,
                "searchable": true,
                "checked": true,
                "width": 40,
            },
            "technology.energy_shields": {
                "name": "энергетические поля",
                "columnName": "ЭП",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.energetics": {
                "name": "энергетика",
                "columnName": "ЭН",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.subspace_travel": {
                "name": "перемещение в подпространстве",
                "columnName": "ПВП",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.baryonic_engine": {
                "name": "барионный двигатель",
                "columnName": "БД",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.annihilation_engine": {
                "name": "аннигиляционный двигатель",
                "columnName": "АД",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.subspace_engine": {
                "name": "подпространственный двигатель",
                "columnName": "ПД",
                "type": "number",
                "sortable": true,
                "searchable": true,
                "checked": true,
                "width": 40,
            },
            "technology.military_lasers": {
                "name": "боевые лазеры",
                "columnName": "БЛ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.photonic_weapon": {
                "name": "фотонное оружие",
                "columnName": "ФО",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.leptonic_weapon": {
                "name": "лептонное оружие",
                "columnName": "ЛО",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.tachyon_scan": {
                "name": "тахионное сканирование",
                "columnName": "ТС",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.planet_exploration": {
                "name": "планетарное сканирование",
                "columnName": "ПСК",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "technology.vibrotron": {
                "name": "вибротрон",
                "columnName": "ВБТ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
        }
    },
    "ship": {
        "name": "Корабли",
        "sub": {
            "ship.shuttle": {
                "name": "шаттл",
                "columnName": "ШТ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.fighter": {
                "name": "истребитель",
                "columnName": "ИС",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.transport": {
                "name": "транспорт",
                "columnName": "ТП",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.energodrone": {
                "name": "энергодрон",
                "columnName": "ЭД",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.attacker": {
                "name": "штурмовик",
                "columnName": "ШМ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.frigate": {
                "name": "фрегат",
                "columnName": "ФР",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.galaction": {
                "name": "галактион",
                "columnName": "ГЛ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.destroyer": {
                "name": "разрушитель",
                "columnName": "РШ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.bombardier":  {
                "name": "бомбардир",
                "columnName": "БД",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.colossus": {
                "name": "колосс",
                "columnName": "КЛC",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.scout": {
                "name": "разведзонд",
                "columnName": "РЗД",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.collector": {
                "name": "коллектор",
                "columnName": "КЛР",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.pioneer": {
                "name": "первопроходец",
                "columnName": "ПП",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "ship.corvette": {
                "name": "корвет",
                "columnName": "КТ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
        }
    },
    "defence": {
        "name": "Оборона",
        "sub": {
            "defence.missile_block": {
                "name": "ракетный блок",
                "columnName": "РБ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "defence.infrared_laser": {
                "name": "инфракрасный лазер",
                "columnName": "ИЛ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "defence.ultraviolet_laser": {
                "name": "ультрафиолетовый лазер",
                "columnName": "УФ",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "defence.graviton_weapon": {
                "name": "гравитонное орудие",
                "columnName": "ГО",
                "type": "number",
                "sortable": true,
                "searchable": true,
                "checked": true,
                "width": 40,
            },
            "defence.photon_cannon": {
                "name": "фотонная пушка",
                "columnName": "ФП",
                "type": "number",
                "sortable": true,
                "searchable": true,
                "checked": true,
                "width": 40,
            },
            "defence.lepton_cannon": {
                "name": "лептонное пушка",
                "columnName": "ЛП",
                "type": "number",
                "sortable": true,
                "searchable": true,
                "checked": true,
                "width": 40,
            },
            "defence.small_energy_shield": {
                "name": "малый энергокупол",
                "columnName": "МЭК",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
            "defence.big_energy_shield": {
                "name": "большой энергокупол",
                "columnName": "БЭК",
                "type": "number",
                "searchable": true,
                "sortable": true,
                "checked": true,
                "width": 40,
            },
        }
    },
    "meta": {
        "name": "Дополнительно",
        "sub": {
            "meta.scannedat": {
                "name": "Дата сканирования",
                "type": "date",
                "sortable": true,
                "checked": true,
                "width": 130,
                "renderFn": dateRenderer
            },
            "meta.tosimulator": {
                "name": "Симулятор",
                "type": "text",
                "sortable": false,
                "searchable": false,
                "columnable": false,
                "width": 130,
            },
            "meta.showreport": {
                "name": "Отчет",
                "type": "text",
                "sortable": false,
                "searchable": false,
                "columnable": false,
                "width": 130,
            }
        }
    }
}
