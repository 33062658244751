<template>
    <div class="fonPlanet " id="fonPlanet">
        <NavComponent 
            ref="NavComponent" 
            @nameUser="nameUser = $event" 
            @nameAlians="nameAlians = $event"
            @selectPlanet="selectPlanetFunc" 
            @send_fleet="send_fleet" 
            @changeNumGalaxy="changeNumGalaxy" 
            @settingShow="settingShow=!settingShow" />

        <TableComponent
            ref="TableComponent"
            class="hi100"
            :nameUser="nameUser"
            :nameAlians="nameAlians"
            :loadingReport="loadingReport"
            :minusFleet="minusFleet"
            :preload="preload"
            :settingShow="settingShow"
            :gridData="gridData" />

        <LogComponent/>
    </div>
</template>

<script>
import TableComponent from '@/components/planets/TableComponent.vue'
import NavComponent from '@/components/basic_component/NavComponent.vue'
import LogComponent from '@/components/basic_component/LogComponent.vue'

export default {
    name: 'Planets',
    components: {
        TableComponent,
        LogComponent,
        NavComponent
    },
    mounted(){
        this.token = localStorage.getItem("glxtoken")

        this.numPlanet = this.getNumPlanetLS()
        this.getGalaxy()
    },
    data(){
        return{
            nameUser:'',
            nameAlians:'',
            popupPage: 0,
            page: 1,
            end: false,
            load: false,
            preload: true,
            settingShow: false,
            selectPlanet: {},
            listPlanet:[],
            timerId: '',
            gridData: [],
            numGalaxy1: 0,
            numSector1: 0,
            numPlanet1: 0,
            numGalaxy2: 0,
            numSector2: 50,
            numPlanet2: 20,
        }
    },
    computed: {
        isProVersion() {
            return this.$store.getters.isProVersion
        }
    },
    methods:{
        getNumPlanetLS() {
            const numPlanetLS = window.localStorage.getItem('numPlanet')

            if (numPlanetLS === null) return 0

            return parseInt(numPlanetLS)
        },
        isUndefined(v) {
            return v === undefined
        },
        changeNumGalaxy(galaxy1, sector1, planet1){
            if (this.isUndefined(galaxy1) || this.isUndefined(sector1) || this.isUndefined(planet1)) {
                this.page = 1
                this.getGalaxy()
                return
            }

            this.numGalaxy1 = parseInt(galaxy1)
            this.numGalaxy2 = parseInt(galaxy1)

            this.numSector1 = parseInt(sector1.split('-')[0])
            this.numSector2 = parseInt(sector1.split('-')[1])

            this.numPlanet1 = parseInt(planet1.split('-')[0])
            this.numPlanet2 = parseInt(planet1.split('-')[1])

            this.page = 1
            this.getGalaxy()
        },

        loadingReport(){
            if (this.end || this.preload) return
            this.page += 1
            this.getGalaxy()
        },
        // planetNum это 
        selectPlanetFunc(planetDataObj){
            this.selectPlanet = planetDataObj
        },
        send_fleet(fleet, count, auto){
            this.$refs.TableComponent.sendFleet(fleet, this.selectPlanet.path, count, auto);
        },

        minusFleet(fleet, count, get){
            return this.$refs.NavComponent.minusFleet(fleet, count, get);
        },
        createParamGet(){
            let str = ''
            if (this.numGalaxy1 > -1 && this.numGalaxy2 > -1) {
                str = str + '&galaxyFrom='+this.numGalaxy1+'&galaxyTo='+this.numGalaxy2
            }
            if (this.numSector1 > -1 && this.numSector2 > -1) {
                str = str + '&sectorFrom='+this.numSector1+'&sectorTo='+this.numSector2
            }
            if (this.numPlanet1 > -1 && this.numPlanet2 > -1) {
                str = str + '&planetFrom='+this.numPlanet1+'&planetTo='+this.numPlanet2
            }
            return str
        },
        async getGalaxy() {
        },
        addGdridData(newData){
            let arr = []
            newData.forEach((item) => {
                arr.push({type:"row", childrens:[item]})
                let i = 0
                if (item.building)
                    for (i = 0; i < item.building.length; i++) {
                        item[item.building[i].name] = item.building[i].level
                    }
                if (item.technologies)
                    for (i = 0; i < item.technologies.length; i++) {
                        item[item.technologies[i].name] = item.technologies[i].level
                    }
                if (item.ships)
                    for (i = 0; i < item.ships.length; i++) {
                        item[item.ships[i].name] = item.ships[i].amount
                    }
                if (item.defences)
                    for (i = 0; i < item.defences.length; i++) {
                        item[item.defences[i].name] = item.defences[i].amount
                    }
            })

            if (this.page > 1 ) {
                this.gridData = this.gridData.concat(arr)
            } else {
                this.gridData = arr
            }

        },
    },

    destroyed(){
        // clearInterval(this.timerId)
    },

}
</script>

<style media="screen">
.popupPage {
    position: fixed;
    max-width: 1000px;
    width: 100%;
    background: var(--planets-popuppage-bg);
    height: 800px;
}
.hi100 {
    height: calc(100% - 85px)!important;
}
.shadow {
    box-shadow: 0px 0px 10px var(--planets-shadow-boxshadow-color);
}
body{
    overflow-y: hidden;
}
.hmin100{
    min-height: 100%;
}

.mt1{
    margin-top: 2px;
}
.mb1{
    margin-bottom: 4px;
}
.fonPlanet {
    /* box-shadow: 0 0 85px #000; */
    /* padding-top: 30px; */
    position: relative;
    background: var(--planets-fonplanet-bg);
    height: 100%;
    max-height: 100vh;
    width: 100%;
    /* filter: blur(); */
}
.yellow_main{
  color: var(--planets-yellowmain-color);
}
.mintext{
    font-size: 13px;
}
.podnav {
  position: fixed;
  left: 0;
  top: 0;
  width:100%;
    height: 30px;
    background: var(--planets-podnav-bg);
    z-index: 10;
}
.mrauto{
    margin-left: auto;
}
.link_plan{
    color: var(--planets-linkplan-color);
    font-size: 12px;
    margin-top: 4px;
}
.link_plan.hov:hover{
    color: var(--planets-linkplan-hover-color);
    font-weight: 400;
}
.btn_task{
    padding: 1px 5px;
    border: none;
    background: var(--planets-btn-task-bg);
    border: 1px solid --planets-btn-task-border-color;
    color: var(--planets-btn-task-color);
}
.btn_task:hover{
    background: var(--planets-btn-task-hover-bg);
    /* color: #2f3136; */
}
.btn_task:focus{
    outline: none;
}

[data-theme="light"] .minbg .mask_text {
    color: white;
}

[data-theme="light"] .minbg .yellow_main {
    color: yellow;
}

[data-theme="light"] .minbg {
    background: #bab8b5;
}

[data-theme="light"] .fonobl {
    color: white;
}
</style>
