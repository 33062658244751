<template id="">
  <div class="summ_fixed">
    <table class="w-100">
      <tbody>
        <tr>
          <td width="150px">
            Всего выбрано:
            <span  class="yellow_main">{{ result.count }}</span>
          </td>
          <td>Ti: <span class="yellow_main">{{ result.resc.ti | numberToLocale }}</span></td>
          <td>Si: <span  class="yellow_main">{{ result.resc.si  | numberToLocale }}</span></td>
          <td>Am: <span  class="yellow_main">{{ result.resc.an  | numberToLocale }}</span></td>
          <td>Ti: <span  class="yellow_main">{{ result.debris.ti | numberToLocale }}</span></td>
          <td>Si: <span  class="yellow_main">{{ result.debris.si  | numberToLocale }}</span></td>
        </tr>
        <tr>
          <td colspan="1" class="text-center">Планеты</td>
          <td colspan="3" class="text-center">Ресурсы</td>
          <td colspan="2" class="text-center">Обломки</td>
        </tr>
      </tbody>
      <!-- <tbody>
        <tr class="">
          <td width="150px">
            Всего выбрано: <span class="yellow_main">{{ countRow }}</span>
          </td>
          <td v-else></td>
          <td
            :key="child_col.name"
            class=""
            v-for="child_col in childrens_coolumns_summ"
          >
            {{ child_col.title }}:
            <span class="yellow_main">{{
              Math.floor(
                countRow[child_col.name] ? countRow[child_col.name] : "0"
              ).toLocaleString("ru")
            }}</span>
          </td>
        </tr>
        <tr>
          <td
            v-for="column in sumTable"
            :key="column.name"
            class="text-center"
            :colspan="column.childrens.length"
          >
            {{ column.title }}
          </td>
        </tr>
      </tbody> -->
    </table>
  </div>
</template>

<script type="text/javascript">
export default {
  props:[
    "selectRowTable",

  ],
  mounted(){
    // this.scrollLeft =  document.getElementById('fonPlanet').scrollLeft
  },
  filters: {
    numberToLocale(n) {
      return Number(n).toLocaleString()
    }
  },
  data(){
    return {
      sumTable: [
				{title:"Планеты", childrens:[
					{title: "Количество планет", width:"15px", name:"planetCount" },
				]},
				{title: "Ресурсы", childrens:[
          {title: "Ti", width:"60px", name:"resTitanium", sort:true, summ:true },
          {title: "Si",  width:"60px", name:"resSilicon", sort:true, summ:true },
          {title: "Am", width:"60px", name:"resAntimatter", sort:true, summ:true },
          // {title: "Валюта", width:"80px", name:"resVal", sort:true, summ:true },
          //{title: "Флот", width:"250px", name:"resShatls", sort:true, summ:true },
        ]},
        {title: "Обломки", childrens:[
          {title: "Ti", width:"60px", name:"obTitanium", sort:true, summ:true },
          {title: "Si", width:"60px", name:"obSilicon", sort:true, summ:true },
          {title: "Am", width:"60px", name:"obAntimatter", sort:true, summ:true },
        ]},
			],
    }
  },
  computed:{
    scrollLeft(){
      return document.getElementById('fonPlanet').scrollLeft
    },
    // childrens_coolumns_summ(){  //возвращает названия колонок для суммы
    //   if (this.childrens_row == undefined) return
    //   let childs = []
    //   this.sumTable.forEach((item) => {
    //     item.childrens.forEach((i) => {
    //       if (i.summ) {
    //         childs.push(i)
    //       }
    //     })
    //   });
    //   return childs
    // },
    result(){ 
      const result = {
        count: 0,
        resc: {
          ti: 0,
          si: 0,
          an: 0
        },
        debris: {
          ti: 0,
          si: 0
        }
      }

      for (let strPath in this.selectRowTable) {
        const rescTi = Math.floor(this.selectRowTable[strPath]['resources.titanium'])
        const rescSi = Math.floor(this.selectRowTable[strPath]['resources.silicon'])
        const rescAn = Math.floor(this.selectRowTable[strPath]['resources.antimatter'])

        const debrTi = Math.floor(this.selectRowTable[strPath]['debris.titanium'])
        const debrSi = Math.floor(this.selectRowTable[strPath]['debris.silicon'])

        result.count += 1
        result.resc.ti += isNaN(rescTi) ? 0 : rescTi
        result.resc.si += isNaN(rescSi) ? 0 : rescSi
        result.resc.an += isNaN(rescAn) ? 0 : rescAn

        result.debris.ti += isNaN(debrTi)  ? 0 : debrTi
        result.debris.si += isNaN(debrSi)  ? 0 : debrSi
      }

      return result

      // return Object.keys(this.selectRowTable).length
      // if (this.childrens_row == undefined) return {}
			// let sumObj = {}, planetCount = 0
			// this.childrens_row.forEach((item) => {
			// 	if (this.selectRowTable[item.title]) {
			// 		planetCount++
			// 		this.childrens_coolumns_summ.forEach((utem) => {
			// 			if (isNaN(sumObj[utem.name])) {
			// 				sumObj[utem.name] = 0 +  Number(item[utem.name])
			// 			}else {
			// 				sumObj[utem.name] = Number(sumObj[utem.name]) +  Number(item[utem.name])
			// 			}
			// 		})
			// 	}
			// })
			// sumObj.planetCount = planetCount
			// return sumObj
		}
  }
}
</script>

<style media="screen" scoped="">
.summ_fixed {
  position: sticky;
  width: 100%;
  bottom: 50px;
  color: var(--summ-fixed-color);
  left: 0;
  right: 0;
  background: var(--summ-fixed-bg);
}
</style>
