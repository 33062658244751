<template id="">
  <div class="w-100 h-100 padd" style="">
    <!-- <div class="preloads" v-if="loading">
      <div
      >
        <div id="squaresWaveG_1" class="squaresWaveG"></div>
        <div id="squaresWaveG_2" class="squaresWaveG"></div>
        <div id="squaresWaveG_3" class="squaresWaveG"></div>
        <div id="squaresWaveG_4" class="squaresWaveG"></div>
        <div id="squaresWaveG_5" class="squaresWaveG"></div>
        <div id="squaresWaveG_6" class="squaresWaveG"></div>
        <div id="squaresWaveG_7" class="squaresWaveG"></div>
        <div id="squaresWaveG_8" class="squaresWaveG"></div>
      </div>
    </div> -->

    <div class="kmask" v-if="report.received" @click="report = {}"></div>

    <div class="reportPopup" v-if="report.received">
      <div class="close link" @click="report = {}">
        <img src="@/assets/img/close1.png" alt="" />
      </div>
      <RigthComponentScan
        style="padding-top: 20px"
        :selectReport="report"
        :key="report.received"
      />
    </div>


    <div class="send-fleet-block" :class="{'opened': sendFleetState}">
      <div class="p-3">
        <table>
          <thead>
            <tr>
              <th colspan="7" class="text-center">Корабли</th>
              <th colspan="2" class="text-center">Грузоподъемность</th>
              <th colspan="3" class="text-center">Добыча</th>
              <th></th>
            </tr>
            <tr>
              <th>Шаттлы ({{ getMaxFleetCount('shuttle') }})</th>
              <th>Транспортники ({{ getMaxFleetCount('transport') }})</th>
              <th>Фрегаты ({{ getMaxFleetCount('frigate') }})</th>
              <th>Галактионы ({{ getMaxFleetCount('galaction') }})</th>
              <th>Бомбардиры ({{ getMaxFleetCount('bombardier') }})</th>
              <th>Коллекторы ({{ getMaxFleetCount('collector') }})</th>
              <th>Разведзонды ({{ getMaxFleetCount('scout') }})</th>
              <th>Полная</th>
              <th>Требуемая</th>
              <th>Ti</th>
              <th>Si</th>
              <th>Am</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><input type="number" min="0" step="1" 
                :max="getMaxFleetCount('shuttle')" 
                @focus="focus" 
                @blur="blur" 
                class="numInp"
                name="shuttle"
                v-model.number="sendFleetData.shuttle"></td>
              <td><input type="number" min="0" step="1" 
                :max="getMaxFleetCount('transport')" 
                @focus="focus" 
                @blur="blur" 
                class="numInp" 
                name="transport"
                v-model.number="sendFleetData.transport"></td>
              <td><input type="number" min="0" step="1" 
                :max="getMaxFleetCount('frigate')" 
                @focus="focus" 
                @blur="blur" 
                class="numInp" 
                name="frigate"
                v-model.number="sendFleetData.frigate"></td>
              <td><input type="number" min="0" step="1" 
                :max="getMaxFleetCount('galaction')" 
                @focus="focus" 
                @blur="blur" 
                class="numInp" 
                name="galaction"
                v-model.number="sendFleetData.galaction"></td>
              <td><input type="number" min="0" step="1" 
                :max="getMaxFleetCount('bombardier')" 
                @focus="focus" 
                @blur="blur" 
                class="numInp" 
                name="bombardier"
                v-model.number="sendFleetData.bombardier"></td>
              <td><input type="number" min="0" step="1" 
                :max="getMaxFleetCount('collector')" 
                @focus="focus" 
                @blur="blur" 
                class="numInp" 
                name="collector"
                v-model.number="sendFleetData.collector"></td>
              <td><input type="number" min="0" step="1" 
                :max="getMaxFleetCount('scout')" 
                @focus="focus"
                @blur="blur"
                class="numInp"
                name="scout"
                v-model.number="sendFleetData.scout"></td>
              <td>{{ allCap | round }}</td>
              <td>{{ needCap | round }}</td>
              <td>{{ tiLoot | round }}</td>
              <td>{{ siLoot | round }}</td>
              <td>{{ amLoot | round }}</td>
              <td class="full"><button @click="sendFleetHandler">Отправить</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mega-filter-opener">
      <div class="row">
        <div class="col-9 filter-where-conds">
          <div
            class="cond"
            v-for="(whereObj, index) in filter.where"
            :key="'filter-opener-where-' + index">
              {{ fields[whereObj.field]["name"] }}
              {{ comp[whereObj.comp] }}
              {{ whereObj.value | whereValueModifier }} <span v-if="whereObj.field !== 'planet.cluster'" @click="removeWhereAndFilter(index)">&#10005;</span></div>
        </div>
        <div class="col-3 text-right">
          <div class="connection-indicator-wrapper">
            <span class="connection-indicator" :style="{ 'background': indicatorColor }" title="Состояние соединения с сервером"></span>
            <span class="connection-indicator-text" v-html="indicatorHtml"></span>
          </div>
          <a @click.prevent="toggleFilter" class="mr-1"><img src="@/assets/img/Burger.png" height="13px" alt=""> <span class="maincolor mintext">Настроить фильтр</span></a>
        </div>
      </div>
    </div>

    <div class="mega-filter" :class="{ opened: filterState }">
      <div class="row px-2 py-2">
        <div class="col-6 saved-filter">
          <h5 class="d-inline-block">Фильтры</h5> <select class="d-inline-block numInp mr-2" v-model="filterActionType">
            <option value="new">Новый</option>
            <option value="load">Загрузить</option>
          </select>
          <template v-if="filterActionType === 'new'">
            <input type="text" class="numInp" v-model="filter.filter_name" placeholder="Введите название">
          </template>
          <template v-else>
            <select class="numInp" v-model="selectedFilterId">
              <option value="-1" selected disabled>Выберите фильтр</option>
              <option
                v-for="(v, i) in storedFilters"
                :value="v.filter_id"
                :key="'store_filter_' + i">
                {{ v.filter_name }}
              </option>
            </select>
          </template>
          
          <button v-if="selectedFilterId > -1" class="m-1" @click="removeFilter" type="button" :disabled="requestStates.removing">
            <template v-if="requestStates.removing">
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Удаление...
            </template>
            <template v-else>
              Удалить
            </template>
          </button>

          <button class="m-1" @click="filterAndSave" type="button" :disabled="requestStates.saving">
            <template v-if="requestStates.saving">
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Сохранение...
            </template>
            <template v-else>
              Сохранить
            </template>
          </button>

          <button class="m-1" @click="filterIt" type="button" :disabled="requestStates.loading">
            <template v-if="requestStates.loading">
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Загрузка...
            </template>
            <template v-else>
              Применить фильтр
            </template>
          </button>
        </div>
        <div class="col-6 cluster-selecter">
          <h5 class="d-inline-block">Кластер</h5> <select v-model.number="sector" class="d-inline-block numInp">
            <option
              v-for="v in sectorVars"
              :key="'sector_var_' + v"
            >{{ v }}</option>
          </select>
        </div>
      </div>
      <div class="row px-4 d-flex align-items-stretch">
        <fieldset class="">
          <legend>Параметры фильтра</legend>

          <div class="row">

            <div class="col-5">
              <!-- <h5>Искать</h5> -->
              <fieldset>
                <legend>Искать</legend>
                <div class="scrollable">
                  <ul>
                    <li v-if="filter.where.length < 1">Нет условий поиска</li>
                    <li
                      v-for="(whereObj, index) in this.filter.where"
                      :key="'where_condition_' + index"
                      :class="['where_' + whereObj.field]">
                      <div v-if="whereObj.cond">
                        {{ cond[whereObj.cond] }}
                      </div>
                      <div>
                        {{ fields[whereObj.field]["name"] }}
                        {{ comp[whereObj.comp] }}
                        {{ whereObj.value | whereValueModifier | localeString(fields[whereObj.field]["type"]) }}
                      </div>
                      <div v-if="whereObj.field !== 'planet.cluster'" class="remove-cross" @click="removeWhere(index)">
                        &#10005;
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="adding-new">
                  <form @submit.prevent="addWhereCond($event)">
                    <select
                      class="d-block numInp mb-1"
                      name="cond"
                      v-if="filter.where.length > 0">
                      <option
                        v-for="(v, k) in cond"
                        :value="k"
                        :key="'cond_where_' + k">
                        {{ v }}
                      </option>
                    </select>
                    <select
                      name="field"
                      v-model="filterWhereTmp.field"
                      class="numInp mr-1">

                      <optgroup
                        v-for="(value, key, index) in searchableFieldsGrouped"
                        :key="'field_where_parent_' + index"
                        :label="key"
                      >
                        <option
                          v-for="(v, index) in value"
                          :value="v.key"
                          :key="'field_where_child_' + index">
                          {{ v.name }}
                        </option>
                      </optgroup>

                    </select>
                    <select
                      name="comp"
                      class="numInp mr-1"
                      v-model="filterWhereTmp.compWhere">
                      <option
                        v-for="(v, k) in availableComparators"
                        :value="k"
                        :key="'comp_where_' + k">
                        {{ v }}
                      </option>
                    </select>
                    <template v-if="filterWhereTmp.type === 'boolean'">
                      <select name="value" class="numInp mr-1">
                        <option value="true">Да</option>
                        <option value="false">Нет</option>
                      </select>
                    </template>
                    <template v-else>
                      <input
                        v-if="needShowWhereInput"
                        ref="whereInputEl"
                        class="numInp mr-1"
                        name="value"
                        min="0"
                        step="1"
                        :value="filterTempValues.where | localeString(filterWhereTmp.type)"
                        @input="event => filterTempValues.where = event.target.value"
                        autocomplete="off"
                        required/>
                    </template>
                    <button type="submit">Добавить</button>
                  </form>
                </div>

              </fieldset>
            </div>
            <div class="col-4">
              <fieldset>
                <legend>Сортировать</legend>
                <div class="scrollable">
                  <ul>
                    <li v-if="filter.order.length < 1">Нет условий сортировки</li>
                    <li
                      v-for="(orderObj, index) in filter.order"
                      :key="'order_' + index">
                      {{ getFieldName(orderObj.field) }} {{ dir[orderObj.dir] }}
                      <div class="remove-cross" @click="removeOrder(index)">
                        &#10005;
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="adding-new">
                  <form @submit.prevent="addOrder($event)">
                    <select name="field" class="numInp mr-1">
                      <optgroup
                        v-for="(value, key, index) in sortableFieldsGrouped"
                        :key="'field_where_parent_' + index"
                        :label="key"
                      >
                        <option
                          v-for="(v, index) in value"
                          :value="v.key"
                          :key="'field_where_child_' + index">
                          {{ v.name }}
                        </option>
                      </optgroup>
                    </select>
                    <select name="dir" class="numInp mr-1">
                      <option
                        v-for="(v, k) in dir"
                        :value="k"
                        :key="'dir_order_' + k">
                        {{ v }}
                      </option>
                    </select>
                    <button type="submit">Добавить</button>
                  </form>
                </div>
              </fieldset>
            </div>
            <div class="col-3">
              <fieldset>
                <legend>Колонки</legend>
                <div class="scrollable checkbox-wrapper">
                  <label
                    v-for="(v, k) in fields"
                    :key="'selectedColumns_' + k"
                    draggable="draggable"
                    @dragstart="startDrag($event, k)">
                    <input type="checkbox" v-model="filter.columns" :value="k" :disabled="v.disabled === true" />
                    {{ v.name }}
                  </label>
                </div>
                <div class="adding-new">
                  <a class="mr-2" @click="resetSelectedColumns">Снять всё</a>
                  <a @click="selectAllColumns">Выделить всё</a>
                </div>

              </fieldset>
            </div>

          </div>
        </fieldset>
      </div>
      <div class="row px-2 pb-2 d-flex align-items-end">
        
        <div class="col-4 text-center">
          
        </div>
      </div>
    </div>
    <div
      class="overflow-auto h-100 table_planet sizeHeight pb-1"
      ref="tableplanet"
      :style="{'height': `calc(100% - ${offsetBottom}px) !important`, 'min-height': `calc(100% - ${offsetBottom}px) !important`}">
      <table id="overflow" class="tableStyle">
        <thead class="tablePlanet_thead">
          <tr class="first-stick">
            <th
              class="text-center"
              v-for="(vpar, kpar) in tableHeaderParent"
              :colspan="vpar.childs.length"
              :key="'parent_' + kpar">
              {{ vpar.name }}
            </th>
          </tr>
          <tr class="second-stick">
            <th
              class="text-center cursor-pointer"
              v-for="child in tableHeaderChild"
              :style="{ 'min-width': (child.width + 7) + 'px', width: (child.width + 7) + 'px', 'max-width': (child.width + 7) + 'px' }"
              @click="sortByColumn(child.key)"
              :title="child.name"
              :key="'child_' + child.key">
              {{ child.columnName || child.name }} {{ getColumnDirArrow(child.key) }}
            </th>
          </tr>
        </thead>

        <tbody class="tablePlanet_tbody">
          <tr
            v-for="(row, index) in tableData"
            :key="'table_row_data_' + index"
            :class="{'selected': isSelectedPlanet(row), 'singleselect': isSingleSelect(row)}"
            :data-rowindex="index"
            @click.shift="selectPlanet(row, 'shift')"
            @click.ctrl="selectPlanet(row, 'ctrl')"
            @click.exact="selectPlanet(row)">
            <td
              v-for="column in tableHeaderChild"
              :key="'table_row_data_' + index + '_cell_' + column.key"
              :class="['column-' + column.key.replace(/\./g, '-')]">

                <span v-if="column.key === 'meta.tosimulator'">
                    <a @click="goToSimulator(row)">Перейти</a>
                </span>

                <span v-else-if="column.key === 'meta.showreport'">
                    <a @click="showReport(row)">Показать</a>
                </span>

                <span 
                  :title="column.key === 'player.alliance' || column.key === 'player.name' ? row[column.key] : ''"
                  v-else>
                    {{
                        ![undefined, null, ''].includes(row[column.key])
                        ? columnRenderer(column.key, row[column.key])
                        : "-"
                    }}
                </span>
            </td>
          </tr>
          <tr
            v-if="loading"
            key="loadingrows">
            <td 
              class="text-center"
              :colspan="tableHeaderChild.length"
            >
              <div class="loading-image-wrapper">
                <img src="@/assets/img/rocket.gif" width="30" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <SummTableComponent
      :selectRowTable="selectedPlanets"
    ></SummTableComponent>
  </div>
</template>

<script type="text/javascript">
/* eslint-disable */
import rawColumns from "./columns";
import SummTableComponent from "@/components/planets/compon/SummTableComponent.vue";
import RigthComponentScan from "@/components/report/RigthComponentScan.vue";
import config from "@/config";
import * as signalR from "@microsoft/signalr/dist/browser/signalr" 
// import { blinkColor } from "@/helpers"
import { gsap } from "gsap"

const DEFAULT_FILTER_NAME = "";

const flattenColumns = (columns) => {
  const r = {};

  for (let key in columns) {
    const prefix = columns[key]["name"];

    for (let subKey in columns[key]["sub"]) {
      r[subKey] = Object.assign({}, columns[key]["sub"][subKey], {
        name: `${prefix}: ${columns[key]["sub"][subKey]["name"]}`,
      });
    }
  }

  return r;
};

const columns = flattenColumns(rawColumns);

const getKeysCheckedFields = (columns) => {
  const r = [];

  for (let k in columns)
    if (columns[k]["checked"]) {
      r.push(k);
    }

  return r;
};

const getAllKeys = (columns) => {
  const r = [];

  for (let k in columns) {
    r.push(k);
  }

  return r;
};

export default {
  components: {
    SummTableComponent,
    RigthComponentScan,
  },
  props: {
    // nameUser: String,
    // nameAlians: String,
    // gridData: Array,
    // settingShow: Boolean,
    //"preload": Boolean,
    minusFleet: Function,
    //loadingReport: Function,
  },
  mounted() {
    // if (parseInt(localStorage.getItem("versionTable")) != this.versionTable) {
    //   console.log("Удален");
    //   localStorage.removeItem("gridColumns");
    //   localStorage.setItem("versionTable", this.versionTable);
    // }

    // if (localStorage.gridColumns) {
    //   this.gridColumns = JSON.parse(localStorage.getItem("gridColumns"));
    // }

    //this.token = localStorage.getItem("glxtoken");

    // document.querySelector(".table_planet").addEventListener('scroll', ()=> {
    //     this.checkAjaxLoading()
    // })
    this.calculateOffsetBottom()
    this.$refs.tableplanet.addEventListener("scroll", this.scrollHandler);

    this.getStoreFilters();
    this.addDefaultCluster()

    this.connectWs()
    this.hubConnectionChecker()
  },
  beforeDestroy() {
    this.$refs.tableplanet.removeEventListener("scroll", this.scrollHandler);
    clearTimeout(this.hubConnectionCheckerId)
    this.hubConnection && this.hubConnection.stop()
  },
  data() {
    return {
      tableData: [],
      preload: false,
      loading: false,
      page: 1,
      take: 100,
      loadDataState: false,
      lastSelectedPlanet: null,
      lastLoadingEmpty: false,

      popup: "Разведзонды отправлены на выбранные 	планеты",
      token: "",
      report: {},
      versionTable: 11,
      colResizable: "",
      keySort: 0,
      columnSort: "",
      selectRowTable: {},
      lastSelectPlanet: false,
      lastSelectAct: false, //false снятие выделения true выделение
      selectSettingCollumn: {},
      // #region FILTERDATA
      savedFilters: [],
      sectorVars: Array.from(Array(1067).keys()),
      filter: {
        //filter_id: null,
        filter_name: DEFAULT_FILTER_NAME,
        where: [],
        order: [],
        columns: getKeysCheckedFields(columns),
      },
      fields: Object.assign({}, columns),
      rawColumns: rawColumns,
      comp: {
        greater: "больше",
        greaterorequal: "больше или равно",
        less: "меньше",
        lessorequal: "меньше или равно",
        equal: "равно",
        notequal: "не равно",
        isnull: "пустое",
        isnotnull: "не пустое",
      },
      cond: {
        and: "И",
        or: "ИЛИ",
      },
      dir: {
        ASC: "по возрастанию",
        DESC: "по убыванию",
      },
      filterWhereTmp: {
        field: "planet.star",
        type: "text",
        compWhere: "equal",
      },
      // используется чисто для организации вывода
      // чисел при добавлении фильтров и сортировки
      filterTempValues: {
        where: ''
      },
      storedFilters: [],
      selectedFilterId: -1,
      // #endregion
      selectedPlanets: {},
      isFilteringOnly: false,
      isSavingAndFiltering: false,
      filterState: true,
      filterActionType: 'new', // new or load
      sendFleetData: {
        shuttle: 0,
        transport: 0,
        frigate: 0,
        galaction: 0,
        bombardier: 0,
        collector: 0,
        scout: 0
      },
      fleetCap: {
        shuttle: 5000,
        transport: 25000,
        frigate: 1500,
        galaction: 750,
        bombardier: 500,
        collector: 20000,
        scout: 0
      },
      requestStates: {
        saving: false,
        loading: false,
        removing: false
      },
      offsetBottom: 73,
      hubConnection: null,
      hubConnectionState: 'off',
      hubConnectionCheckerId: 0
    };
  },
  filters: {
      whereValueModifier(value) {
        if (value === true || value === 'true' ||
            value === false || value === 'false') {
            return value + '' === "true" ? "Да" : "Нет"
        }

        return value
      },
      round(value) {
        if (value < 10000) {
          return Math.trunc(value)
        }

        let suffix = ''

        while (value >= 1000) {
          value /= 1000
          suffix += 'k'
        }

        return `${Math.trunc(value)} ${suffix}`
      },
      localeString(value, type) {
        if (type === 'number' && value !== '') {
          const num = parseInt((value + '').replace(/\s+/g, ''))
          if (isNaN(num)) {
            return 0
          }

          return num.toLocaleString()
        }

        return value
      }
  },
  //#region COMPUTED
  computed: {
    // filterWhere() {
    //   return this.filter.where.filter(i => i.field !== 'planet.cluster')
    // },
    userToken() {
      return this.$store.getters.token
    },
    selectedPlanetCluster() {
      return this.$store.getters.selectedPlanetCluster
    },
    selectedPlanetPath() {
      return this.$store.getters.selectedPlanetPath
    },
    // filterState() {
    //   return this.$store.getters.filterState;
    // },
    availableComparators() {
      const selectedField = this.filterWhereTmp.field;
      const availableComparators = this.fields[selectedField].compOnly;

      if (Array.isArray(availableComparators)) {
        const r = {};
        for (let k in this.comp) {
          if (availableComparators.includes(k)) {
            r[k] = this.comp[k];
          }
        }

        return r;
      }

      return this.comp;
    },
    searchableFields() {
      const r = {}

      for (let key in this.fields) {
          if (this.fields[key].searchable !== true) {
              continue
          }

          if (key === 'planet.cluster' && this._existInWhereFilter(key)) {
              continue
          }

          r[key] = this.fields[key]
      }

      return r

    //   return Object.keys(this.fields).reduce((prev, key) => {
    //     if (
    //       // если можно использовать поле в поиске
    //       this.fields[key].searchable !== false 
    //       // и фильтр можно использовать только один раз 
    //       || (key === 'planet.cluster' && this.filter.where.filter(i => i.field === 'planet.cluster').length < 1)
    //     ) {
    //       prev[key] = this.fields[key];
    //     }

    //     return prev;
    //   }, {});
    },
    // _fieldsGrouper(inputData) {
      
    // },
    searchableFieldsGrouped() {
      const r = {}

      for (let key in this.searchableFields) {
        const label = this.searchableFields[key].name
        const labelPairsArray = label.split(':')
        const firstPair = labelPairsArray[0].trim()
        const secondPair = labelPairsArray[1].trim()

        if (r[firstPair] === undefined) {
          r[firstPair] = []
        }

        r[firstPair].push({
          key: key,
          name: secondPair
        })
      }

      return r
    },
    tableHeaderParent() {
      const r = {};

      for (let parentKey in rawColumns) {
        for (let childKey in rawColumns[parentKey]["sub"]) {
          if (this.filter.columns.includes(childKey)) {
            if (r[parentKey] === undefined) {
              r[parentKey] = {
                name: "",
                childs: [],
              };
            }

            r[parentKey]["name"] = rawColumns[parentKey]["name"];
            r[parentKey]["childs"].push({
              key: childKey,
              name: rawColumns[parentKey]["sub"][childKey]["name"],
              columnName: rawColumns[parentKey]["sub"][childKey]["columnName"],
              width: rawColumns[parentKey]["sub"][childKey]["width"]
            });
          }
        }
      }

      return r;
    },
    tableHeaderChild() {
      const r = [];

      for (let pK in this.tableHeaderParent) {
        for (let child of this.tableHeaderParent[pK]["childs"]) {
          r.push(child);
        }
      }

      return r;
    },
    isProVersion() {
      return this.$store.getters.isProVersion;
    },
    sortableFields() {
      const r = {};

      for (let k in this.fields) {
        if (
          this.fields[k].sortable &&
          this.filter.order.filter((v) => v.field === k).length < 1
        ) {
          r[k] = this.fields[k];
        }
      }

      return r;
    },
    sortableFieldsGrouped() {
      const r = {}

      for (let key in this.sortableFields) {
        const label = this.sortableFields[key].name
        const labelPairsArray = label.split(':')
        const firstPair = labelPairsArray[0].trim()
        const secondPair = labelPairsArray[1].trim()

        if (r[firstPair] === undefined) {
          r[firstPair] = []
        }

        r[firstPair].push({
          key: key,
          name: secondPair
        })
      }

      return r
    },
    needShowWhereInput() {
      return !["isnull", "isnotnull"].includes(this.filterWhereTmp.compWhere);
    },
    sector: {
      get() {
        const objRef = this.filter.where.find(i => i.field === 'planet.cluster')

        if (!Array.isArray(this.filter.where) || 
            this.filter.where.length < 1 ||
            objRef === undefined) {
          return 0
        }
  
        return objRef.value
      },
      set(value) {
        const obj = this.filter.where.find(i => i.field === 'planet.cluster')

        if (!obj) {
          return this.addDefaultCluster(value)
        }

        return obj.value = value
      }
    },
    sendFleetState() {
      return this.$store.getters.sendFleetState
    },
    allCap() {
      let result = 0

      for (let k in this.sendFleetData) {
        if (this.sendFleetData[k] > 0) {
          result += this.sendFleetData[k] * this.fleetCap[k]
        }
      }

      return result
    },
    needCap() {
      return (this.tiLoot + this.siLoot + this.amLoot) / 2
    },
    tiLoot() {
      let result = 0

      for (let k in this.selectedPlanets) {
        if (this.selectedPlanets[k]['resources.titanium']) {
          result += this.selectedPlanets[k]['resources.titanium']
        }
      }

      return result
    },
    siLoot() {
      let result = 0

      for (let k in this.selectedPlanets) {
        if (this.selectedPlanets[k]['resources.silicon']) {
          result += this.selectedPlanets[k]['resources.silicon']
        }
      }

      return result
    },
    amLoot() {
      let result = 0

      for (let k in this.selectedPlanets) {
        if (this.selectedPlanets[k]['resources.antimatter']) {
          result += this.selectedPlanets[k]['resources.antimatter']
        }
      }

      return result
    },
    indicatorColor() {
      switch (this.hubConnectionState) {
        case 'on':
          return 'green'
        case 'off':
          return 'red'
        case 'load':
          return 'yellow'
      }

      return 'red'
    },
    indicatorHtml() {
      switch (this.hubConnectionState) {
        case 'on':
          return 'Подключен'
        case 'load':
          return 'Подключение...'
      }

      return `<a click="javascript:window.location.reload();">Отключен</a>`
    }
  },
  //#endregion
  //#region METHODS
  methods: {
    hubConnectionChecker() {
      this.hubConnectionCheckerId = setTimeout(() => {
        let state = 'off'

        if (!this.hubConnection || !this.hubConnection.state) {
          state = 'off'
        } else {
          switch(this.hubConnection.state) {
            case 'Connected':
              state = 'on'
              break
            case 'Disconnected':
              state = 'off'
              break
            case 'Connecting':
            case 'Disconnecting':
            case 'Reconnecting':
               state = 'load'
               break
          }
        }

        this.hubConnectionState = state
        this.hubConnectionChecker.call(this)
      }, 1000)
    },
    async connectWs() {
      const token = this.userToken
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(config.wsUrl, { accessTokenFactory: () => token })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            if (retryContext.previousRetryCount < 15) {
              return retryContext.previousRetryCount * 1000
            } else {
              return 15 * 1000
            }
          }
        })
        .configureLogging(signalR.LogLevel.None)
        .build()

      hubConnection.serverTimeoutInMilliseconds = 40000
      hubConnection.keepAliveIntervalInMilliseconds = 20000

      try {
        await hubConnection.start()
        this.hubConnection = hubConnection
        this.hubConnection.on('GalaxyUpdate', this.updateTableData.bind(this))
        this.hubConnection.onreconnected(() => {
          this.hubConnection.invoke('Subscribe', 'report')
        })
        this.hubConnection.invoke('Subscribe', 'report')

        this.hubConnection.onclose(err => {
          console.log(err)
        })
      } catch (err) {
        console.error(err)
      }
    },
    updateTableData(rawMessage) {
      let json = JSON.parse(rawMessage)

      json = this._flattenObjectAndCluster(json)
      
      let cluster = json['planet.cluster']
      let star = json['planet.star']
      let planet = json['planet.planet']

      for (let index in this.tableData) {
        if (this.tableData[index]['planet.cluster'] === cluster &&
            this.tableData[index]['planet.star'] === star &&
            this.tableData[index]['planet.planet'] === planet)
        {
          const newObject = Object.assign({}, this.tableData[index], json)
          this.$set(this.tableData, index, newObject)
          this._blinkRow(index)
          break
        }
      }
    },
    _blinkRow(index) {
      this.$nextTick(() => {
        const row = document.querySelector(`[data-rowindex="${index}"]`)
        if (!row) {
          return
        }
        gsap.from(row, {
          duration: 0.2,
          repeat: 1,
          opacity: 0
        })
        //blinkColor(row)

        // row.classList.add('blink')

        // setTimeout(() => {
        //   row.classList.remove('blink')
        // }, 700)
      })
    },
    sortByColumn(field) {
      for (let objIndex in this.filter.order ) if (this.filter.order[objIndex]['field'] === field) {
        if (this.filter.order[objIndex]['dir'] === 'ASC') {
          this.filter.order[objIndex]['dir'] = 'DESC'
        } else {
          this.filter.order.splice(objIndex, 1)
        }

        return this.filterIt()
      }

      this.filter.order.push({ field, dir: 'ASC' })
    },
    getColumnDirArrow(field) {
      for (let obj of this.filter.order) {
        if (obj.field === field) {
          if (obj.dir === 'ASC')  {
            return '▲'
          } else {
            return '▼'
          }
        }
      }

      return ''
    },
    focus(ev) {
      if (ev.target.value < 1) {
        ev.target.value = ''
      }
    },
    blur(ev) {
      const val = ev.target.value
      const max = ev.target.max
      const name = ev.target.name

      if (val < 1) {
        this.sendFleetData[name] = 0
        return
      }

      if (val >= max) {
        this.sendFleetData[name] = max
        return
      }
    },
    async sendFleetHandler() {
      const distArray = Object.keys(this.selectedPlanets)

      const fleetCount = Object.keys(this.sendFleetData).reduce((acc, cur) => acc + this.sendFleetData[cur], 0)

      if (fleetCount < 1) {
        return this.$notify({
          type: 'error',
          text: 'Необходимо указать хотя бы один корабль для отправки'
        })
      }

      if (distArray.length < 1) {
        return this.$notify({
          type: 'error',
          text: 'Для отправки флота необходимо выбрать целевые планеты'
        })
      }

      const srcPlanetPath = this.selectedPlanetPath
      const action = this.getFleetAction()

      for (let dist of distArray) {
        try {
          await this.sendFleetToOneDist(this._getArrayPath(srcPlanetPath), this._getArrayPath(dist), action)
          this.$notify({
            type: 'success',
            text: `Отправляем корабли на ${dist}`
          })
        } catch (err) {
          this.$notify({
            type: 'error',
            text: `Не удалось отправить корабли на ${dist}`
          })

          console.error(err)
        }
      }

      this._zeroSendFleetCount()
    },
    async sendFleetToOneDist(from, destination, action) {
      const fleet = {}

      for (let k in this.sendFleetData) {
        if (this.sendFleetData[k] > 0) {
          fleet[k] = this.sendFleetData[k]
        }
      }

      const requestData = {
        action,
        from,
        destination,
        fleet
      }

      const { data } = await this.$axios.post('/action/fleet', requestData)
      return data.status
    },
    _zeroSendFleetCount() {
      for (let k in this.sendFleetData) {
        this.sendFleetData[k] = 0
      }
    },
    getFleetAction() {
      const hasCollectors = this.sendFleetData.collector > 0
      const hasScouts = this.sendFleetData.scout > 0

      const hasAttackers = Object.keys(this.sendFleetData).reduce((acc, cur) => {
        if (['shuttle', 'frigate', 'galaction', 'bombardier'].includes(cur)) {
          return acc + this.sendFleetData[cur]
        }

        return acc + 0
      }, 0) > 0

      if (hasAttackers) {
        return 'attack'
      }

      if (hasScouts) {
        return 'espionage'
      }

      if (hasCollectors) {
        return 'recycle'
      }

      throw new Error('Неопределенное действие')
    },
    toggleFilter() {
      this.filterState = !this.filterState
    },
    addDefaultCluster(v) {
      this.filter.where.push({
        comp: "equal",
        field: "planet.cluster",
        pos: 0,
        value: v || this.selectedPlanetCluster
      })
    },
    _exitsUncompletedWhere() {
      const inputEl = this.$refs.whereInputEl

      if (inputEl === undefined) {
        return false
      }

      if (inputEl.value && inputEl.value.length > 0) {
        return true
      }

      return false
    },
    _existInWhereFilter(key) {
        for (let obj of this.filter.where) {
            if (obj.field === key) {
                return true
            }
        }

        return false
    },
    isSelectedPlanet(row) {
        return this.selectedPlanets[this._getStrPath(row)] !== undefined
    },
    isSingleSelect(row) {
      return this.isSelectedPlanet(row) && Object.keys(this.selectedPlanets).length == 1
    },
    selectPlanet(row, modif = null) {
        const isOnlyOne = modif === null
        const isCtrl = modif === 'ctrl'
        const isShift = modif === 'shift'

        const currentSelectedRowPath = this._getStrPath(row)

        if (isOnlyOne || isCtrl) {
            this.lastSelectedPlanet = currentSelectedRowPath
        }

        if (isOnlyOne) {
            this.selectedPlanets = {}
            return this.selectedPlanets[currentSelectedRowPath] = Object.assign({}, row)
        }

        if (isCtrl) {
            return this.$set(this.selectedPlanets, currentSelectedRowPath, Object.assign({}, row))
            //return this.selectedPlanets[this._getStrPath(row)] = Object.assign({}, row)
        }

        if (isShift) {
            if (!this.lastSelectedPlanet) {
                return this.selectPlanet(row, null)
            }
            
            let headFound = false

            for (let tableRow of this.tableData) {
                const strPath = this._getStrPath(tableRow)

                if (strPath === currentSelectedRowPath || strPath === this.lastSelectedPlanet) {
                    if (!headFound) {
                        headFound = true
                    } else {
                        headFound = false
                        this.lastSelectedPlanet = null
                        this.$set(this.selectedPlanets, strPath, Object.assign({}, tableRow))
                    }
                }

                if (headFound) {
                    this.$set(this.selectedPlanets, strPath, Object.assign({}, tableRow))
                }
            }
            
        }

    },
    _fillWithTransportAndShuttle() {

    },
    _getStrPath(row) {
        const strPath = `[${row['planet.cluster']}:${row['planet.star']}:${row['planet.planet']}]`
        return strPath
    },
    _getArrayPath(strPath) {
      return strPath.replace(/[\[\]]+/g, '').split(':').map(i => parseInt(i)) // eslint-disable-line
    },
    clusterExistInWhereChecker() {
      if (
        this.filter.where.filter((i) => i.field === "planet.cluster").length < 1
      ) {
        this.$notify({
          text: "Обязательно необходимо указать кластер по которому будет осуществляться поиск",
          type: "error",
        });

        throw new Error("Нельзя фильтровать без указания кластера");
      }
    },
    async scrollHandler() {
        const el = this.$refs.tableplanet
        if ((el.scrollTop + el.clientHeight + 5 >= el.scrollHeight) && !this.loading && !this.lastLoadingEmpty) {
            this.page += 1
            let data = null
            try {
                data = await this.getData(this.filter, this.page, this.take)
            } catch (err) {
                this.page -= 1
            }

            console.log(data)

            if (data === null || data === undefined || data.length < 1) {
                return this.lastLoadingEmpty = true
            }

            this.tableData = this.tableData.concat(this.prepareData(data))
        }
    },
    getSubColumnsCount(parentKey) {
      let r = 0;

      for (let k in rawColumns[parentKey]["sub"])
        if (this.filter.columns.includes(k)) {
          r += 1;
        }

      return r;
    },
    showParent(parentKey) {
      return this.getSubColumnsCount(parentKey) > 0;
    },
    showSub(subKey) {
      return this.filter.columns.includes(subKey);
    },
    columnRenderer(key, value) {
      const renderFn = columns[key]["renderFn"];

      if (!renderFn) {
        return value;
      }

      return renderFn.call(null, value);
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemName", item.name);
    },
    getColumnName(key) {
      return columns[key].name;
    },
    async removeFilter() {
      this.requestStates.removing = true
      try {
        const { status } = await this.$axios.delete(
          `/galaxy/filter?id=${this.selectedFilterId}`
        );
  
        if (status !== 200) {
          return this.$notify({
            type: "error",
            text: "Не удалось удалить фильтр",
          });
        }
      } catch (err) {
        console.error(err)
      }

      this.requestStates.removing = false
      this.selectedFilterId = -1;
      this.filter.filter_name = DEFAULT_FILTER_NAME;
      this.getStoreFilters();
    },
    async getStoreFilters() {
      const { data } = await this.$axios.get(`/galaxy/filter`);

      this.storedFilters = data;
    },
    exist(v) {
      return v !== undefined
    },
    prepareData(rawData) {
      const r = [];
      const initObj = this.filter.columns.reduce((prev, cur) => {
        prev[cur] = undefined;
        return prev;
      }, {});

      for (let raw of rawData) {  
        r.push(Object.assign({}, initObj, this._flattenObjectAndCluster(raw)));
      }

      return r;
    },
    _flattenObjectAndCluster(raw) {
      const objOfArray = {}

      for (let key in raw) {
        if (Array.isArray(raw[key]) && raw[key].length > 0) {
          for (let item of raw[key]) {
            // const replacer = {
            //   'buildings': 'building',
            //   'defences': 'defence',
            //   'spaceships': 'ship',
            //   'technologies': 'technology'
            // }
            objOfArray[`${key}.${item.name}`] = item.value
          }
        }

        
      }

      if (this.exist(raw['planet.cluster']) &&
          this.exist(raw['planet.star']) &&
          this.exist(raw['planet.planet']))
      {

        raw['planet.path'] = `[${raw['planet.cluster']}:${raw['planet.star']}:${raw['planet.planet']}]`
      }

      return Object.assign({}, raw, objOfArray)
    },
    async getData(filter, page = 1, take = 50) {
      this.loading = true

      //filter.columns = filter.columns.filter(i => !['meta.tosimulator', 'meta.showreport'].includes(i))

      const { data } = await this.$axios.post(
        `/galaxy?page=${page}&take=${take}`,
        filter
      );
      this.loading = false
      return data.items;
    },
    async filterIt() {
      this.requestStates.loading = true

      this.clusterExistInWhereChecker();
      
      if (this._exitsUncompletedWhere()) {
        return this.$notify({
          type: 'warn',
          text: 'У вас осталось недобавленное условие в разделе Искать'
        })
      }

      this.page = 1
      this.lastLoadingEmpty = false

      this.filterState = false
      this.tableData = []
      
      let result = []
      try {
        this.isFilteringOnly = true
        result = await this.getData(this.filter, this.page, this.take)
      } catch (err) {
        this.$notify({
          type: 'error',
          text: 'Возникла ошибка при фильтрации'
        })
      } finally {
        this.isFilteringOnly = false
        this.requestStates.loading = false
      }

      this.tableData = this.prepareData(result);
    },
    async filterAndSave() {
      this.requestStates.saving = true
      if (this._exitsUncompletedWhere()) {
        this.requestStates.saving = false
        return this.$notify({
          type: 'warn',
          text: 'У вас осталось недобавленное условие в разделе Искать'
        })
      }

      if (!this.filter.filter_name.trim()) {
        this.requestStates.saving = false
        return this.$notify({
          type: 'error',
          text: 'Нельзя сохранить фильтр без указания названия'
        })
      }

      try {
        this.isSavingAndFiltering = true

        if (this.selectedFilterId > 0) {
          await this.$axios.put(
            `/galaxy/filter`,
            Object.assign(
              {
                filter_id: this.selectedFilterId,
              },
              this.filter
            )
          );
        } else {
          await this.$axios.post("/galaxy/filter", this.filter);
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.requestStates.saving = false
        this.isSavingAndFiltering = false
      }

      this.getStoreFilters();
      //this.filterIt();
    },
    goToSimulator(path) {
        path = `[${path['planet.cluster']}:${path['planet.star']}:${path['planet.planet']}]`
      localStorage.setItem(
        "pathSimulator",
        path.replace("]", "").replace("[", "")
      );
      this.$router.push("Simulator");
    },
    showReport(row) {
      let reportReceivedAt = 0

      if (row['meta.scannedat'] > 1) {
        reportReceivedAt = row['meta.scannedat']
      }

      let i;
      this.report = {
        items: {
          planet_name: row['planet.name'],
          player_name: row['player.name'],
          planet_path: `[${row['planet.cluster']}:${row['planet.star']}:${row['planet.planet']}]`.replace("]", "").replace("[", "").split(":"),
          report: {
            resources: {
              titanium: row['resources.titanium'],
              silicon: row['resources.silicon'],
              antimatter: row['resources.antimatter'],
              energy: null,
            },
            debris: {
              silicon: row['debris.silicon'],
              titanium: row['debris.titanium'],
            },
            technologies: {},
            spaceships: {},
            defences: {},
            buildings: {},
          },
        },
        received: reportReceivedAt,
      };
      if (row.technology)
        for (i = 0; i < row.technology.length; i++) {
          // console.log();
          this.report.items.report.technologies[row.technology[i].name] =
            row.technology[i].value;
        }
      else this.report.items.report.technologies = null;
      if (row.ship)
        for (i = 0; i < row.ship.length; i++) {
          this.report.items.report.spaceships[row.ship[i].name] =
            row.ship[i].value;
        }
      else this.report.items.report.spaceships = null;
      if (row.defence)
        for (i = 0; i < row.defence.length; i++) {
          this.report.items.report.defences[row.defence[i].name] =
            row.defence[i].value;
        }
      else {
        this.report.items.report.defences = null;
      }
      if (row.building)
        for (i = 0; i < row.building.length; i++) {
          this.report.items.report.buildings[row.building[i].name] =
            row.building[i].value;
        }
      else this.report.items.report.buildings = null;

    },
    sendFleet(fleet, pathFrom, count, auto = false) {
      count = parseInt(count)

      if (!this.isProVersion) {
        return this.$notify({
          type: "error",
          text: "Отправка разведзондов и коллекторов из КЦ доступна лишь на PRO-версии",
          duration: 5000,
        });
      }

      let urk, name_fleet;
      if (fleet == "scout") {
        urk = "action/SendScout";
        name_fleet = "Разведзонды";
      } else if (fleet == "collector") {
        urk = "action/SendCollector";
        name_fleet = "Коллекторы";
      }

      let count_fleet = 0;
      for (var i = 0; i < this.tableData.length; i++) {
        const strPath = this._getStrPath(this.tableData[i])

        if (this.selectedPlanets[strPath]) {
          if (auto && fleet == "collector") {
            // Автоматически расчитывает колличество коллеткоров
            let maxCount = this.minusFleet(fleet, count, true); // получить доступное колличество коллекторов.
            let autoCount = Math.ceil(
              ( (this.tableData[i]['debris.titanium'] ? this.tableData[i]['debris.titanium'] : 0) 
              + (this.tableData[i]['debris.silicon'] ? this.tableData[i]['debris.silicon'] : 0)
              ) / 20000
            );

            if (maxCount < autoCount) {
              count = maxCount;
            } else {
              count = autoCount;
            }
            console.log("нужно коллекторов:" + autoCount, maxCount);
          }

          if (count < 1) {
            continue;
          }
          // alert(this.childrens_row[i].title)

          this.$axios
            .post(urk, {
              from: pathFrom.split(":").map(i => parseInt(i)),
              destination: [
                this.tableData[i]['planet.cluster'],
                this.tableData[i]['planet.star'],
                this.tableData[i]['planet.planet']
              ],
            //   destination: this.childrens_row[i].path
            //     .replace("[", "")
            //     .replace("]", "")
            //     .split(":"),
              count: count,
            })
            .then((res) => {
              // console.log(res)
              res;
            });
          count_fleet = this.minusFleet(fleet, count);

          if (count_fleet < 0) {
            this.$notify({
              title: "Флот отправлен.",
              text:
                name_fleet +
                " отправлены не на все выбранные планеты. \n Не хватило кораблей",
            });
            break;
          }
        }
      }
      if (count_fleet > 0) {
        this.$notify({
          title: "Флот отправлен.",
          text: name_fleet + " отправлены на выбранные планеты.",
        });

        setTimeout(() => {
          this.$store.dispatch("loadUsedFleets");
        }, 500);
      }
    },
    _fd2Obj(fd) {
      const r = {};

      for (let k of fd.keys()) {
        let value = fd.get(k)

        // if (value === 'true' || value === 'false') {
        //     value = value === 'true' ? true : false
        // }

        r[k] = value
      }

      return r;
    },
    addWhereCond(ev) {
      console.log(ev)
      const fd = new FormData(ev.target);
      this.filterTempValues.where = ''
      const fdObj = this._fd2Obj(fd)

      const fieldInfo = this.fields[ fdObj.field ]
      console.log(fieldInfo)
      if (fieldInfo !== undefined && fieldInfo.type === 'number') {
        fdObj.value = parseInt(fdObj.value.replace(/\s+/g, ''))
      }

      this.filter.where.push(
        Object.assign(
          {
            pos: this.filter.where.length,
          },
          fdObj
        )
      );

      ev.target.reset();

    },
    addOrder(ev) {
      if (this.filter.order.length >= config.filterMaxOrder) {
        return this.$notify({
          type: "error",
          text: `Максимальное кол-во полей по которым можно сортировать: ${config.filterMaxOrder}`,
        });
      }

      const fd = new FormData(ev.target);
      this.filter.order.push(this._fd2Obj(fd));
    },
    removeWhere(index) {
      this.filter.where.splice(index, 1);
      this.checkAndFixWhere()
    },
    removeWhereAndFilter(index) {
      this.filter.where.splice(index, 1)
      this.checkAndFixWhere()
      this.filterIt()
    },
    checkAndFixWhere() {
      const whereCopy = Array.from(this.filter.where)

      for(let i = 0, max = whereCopy.length; i < max; i += 1) {
        if (i === 0 && whereCopy[i].cond !== undefined) {
          delete(whereCopy[i].cond)
        }

        whereCopy[i].pos = i
      }

      this.filter.where = whereCopy
    },
    removeOrder(index) {
      this.filter.order.splice(index, 1);
    },
    getFieldName(field) {
      return this.fields[field]["name"] || field;
    },
    resetSelectedColumns() {
      // HOT FIX 
      // TODO сделать нормально 
      this.filter.columns = ['planet.cluster', 'planet.star', 'planet.planet'];
    },
    selectAllColumns() {
      this.filter.columns = getAllKeys(columns);
    },
    getMaxFleetCount(type) {
      return this.$store.getters.getMaxFleetCount(type)
    },
    calulateTransportAndShuttles(selectedPlanets) {
      if (Object.keys(selectedPlanets).length > 1 && this.sendFleetState)  {
        return this.$notify({
          type: 'warn',
          text: 'Отправка флота возможна только на одну планета за раз'
        })
      }

      let needCap = this.needCap + 0

      let transportCount = this.getMaxFleetCount('transport')
      let transportCap = this.fleetCap['transport']
      let needTransportCount = 0

      let shuttleCount = this.getMaxFleetCount('shuttle')
      let shuttleCap = this.fleetCap['shuttle']
      let needShuttleCount = 0


      while (needCap > 0 && transportCount > 0) {
        needCap -= transportCap
        needTransportCount += 1
        transportCount -= 1
      }

      while (needCap > 0 && shuttleCount > 0) {
        needCap -= shuttleCap
        needShuttleCount += 1
        shuttleCount -= 1
      }

      this.sendFleetData.transport = needTransportCount
      this.sendFleetData.shuttle = needShuttleCount
    },
    calculateOffsetBottom() {
      this.$nextTick(() => {
        this.offsetBottom = this.$refs.tableplanet.getBoundingClientRect().top + 9
      })
    }
  },
  //#endregion
  watch: {
    "filterWhereTmp.field": function () {
      this.filterWhereTmp.type = this.fields[this.filterWhereTmp.field]
        ? this.fields[this.filterWhereTmp.field]["type"]
        : "text";
      this.filterTempValues.where = ''
    },
    selectedFilterId: function (newValue) {
      if (newValue > 0) {
        for (let sF of this.storedFilters)
          if (sF.filter_id == newValue) {
            this.filter = sF;
          }
      }
    },
    filterActionType: function (newValue) {
      if (newValue === 'new') {
        this.filter.filter_name = DEFAULT_FILTER_NAME
        this.selectedFilterId = -1
        this.filter.filter_id = -1
      }
    },
    selectedPlanets: function (newValue) {
      this.calulateTransportAndShuttles(newValue)
    },
    filterState: function () {
      this.calculateOffsetBottom()
    },
    sendFleetState: function () {
      this.calculateOffsetBottom()
    }
  },
};
</script>

<style media="screen" scoped="">

.cluster-selecter select option,
.saved-filter select option {
  text-align: center;
  font-size: 12px;
  padding: 0 3px;
}

.cluster-selecter select,
.saved-filter select,
.saved-filter input {
  font-size: 12px;
}

.saved-filter input {
  min-width: 150px;
}

.saved-filter select.numInp {
  max-width: 100%;
}

.close {
  position: absolute;
  right: 15px;
  top: 8px;
  z-index: 1000;
}
.close.link {
  cursor: pointer;
}
.reportPopup {
  position: absolute;
  z-index: 1000;
  width: 80%;
  height: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  box-shadow: 0 0 50px var(--table-report-box-shadow);
}
.kmask {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--table-kmask-bg);
  height: 100%;
  width: 100%;
  z-index: 999;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}

.preloads {
  position: absolute;
  z-index: 1;
  width: 100%;
}
.tablePlanet_thead tr.first-stick th,
.tablePlanet_thead tr.second-stick th {
  /* border: 1px solid #606060; */
  /* box-shadow: inset 0 1px 0 #606060, inset 0 -1px 0 #606060, inset 1px 0 0 #606060, inset -1px 0 0 #606060; */
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background: var(--table-thead-bg);
  height: 21px;
}

.tablePlanet_thead tr.second-stick th {
  top: 21px;
}

.tablePlanet_thead .second-stick {
  cursor: pointer;
  user-select: none;
  padding: 3px 5px !important;
  white-space: nowrap;
}

.tableStyle {
  table-layout: fixed;
  /* border-collapse: collapse; */
  /* width: 2000px; */
  font-size: 13px;
  color: var(--table-tablestyle-color);
}
/deep/ .border-none {
  border: none !important;
}
.link_cursor {
  cursor: pointer;
}
/deep/th {
  border: 1px solid var(--table-th-border-color);
  font-weight: 400;
  padding: 0 10px !important;
  padding-bottom: 5px;
}
/deep/ td {
  border: 1px solid var(--table-td-border-color);
  font-size: 12px;
  padding: 0 10px !important;
  padding-bottom: 5px;
}

/deep/ td.disable-select {
  user-select: none; 
  -ms-user-select: none; 
  -moz-user-select: none; 
  -webkit-user-select: none; 
}


.pb50 {
  padding-bottom: 50px;
}
.poleSettingTable {
  z-index: 10;
  width: 100%;
  color: var(--table-pole-color);
  background: var(--table-pole-bg);
  min-height: 50px;
}

input[type="checkbox"] {
  display: none;
}

.pseudocheckbox::before {
  content: "\00A0";
  display: inline-block;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  background-color: var(--sender-pseudo-bg);
  border: 2px solid var(--sender-pseudo-border-color);
  border-radius: 0px;
  vertical-align: baseline;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: var(--sender-pseudo-color);
  margin-bottom: 0;
  cursor: pointer;
}

input[type="checkbox"]:checked + .pseudocheckbox::before {
  content: "\2713";
}
.thSetting {
  cursor: pointer;
  min-width: 100px;
}
.sizeHeight {
  height: calc(100% - 75px) !important;
  width: 100%;
}
.thSetting.select {
  background: var(--table-thsetting);
}
.lef {
  left: 0;
}
.rig {
  right: 0;
}
.but {
  height: 100%;
  padding: 0px 10px;
  background: var(--table-but-bg);
  top: 0px;
  position: absolute;
  display: none;
  z-index: 99;
}
.thSetting:hover > .but {
  display: block;
}
.disnone {
  display: none !important;
}


.adding-new * {
  font-size: 12px;
}

.adding-new select {
  max-width: 150px;
}

.mega-filter {
  color: var(--table-mega-filter-color);
}

.mega-filter ul {
  padding-left: 0;
}

.mega-filter li {
  list-style: none;
  background: var(--table-mega-filter-bg);
  margin: 3px 0 5px;
  padding: 3px 5px;
  position: relative;
  user-select: none;
  border-radius: 5px;
  font-size: 12px;
}

.mega-filter li .remove-cross {
  position: absolute;
  top: 0px;
  right: 3px;
  cursor: pointer;
}

.mega-filter input[type="checkbox"] {
  display: inline;
}

.mega-filter .scrollable {
  max-height: 70px;
  overflow-x: auto;
}

.mega-filter .scrollable label {
  display: block;
  font-size: 12px;
}

.mega-filter .checkbox-wrapper {
  background: var(--table-mega-filter-bg);
  padding: 3px 5px;
  border-radius: 5px;
}

.numInp {
  max-width: 80px;
  background: var(--table-numinp-bg);
  border: 1px solid var(--table-numinp-border);
  /* border: none; */
  color: var(--table-numinp-color);
}
.numInp:focus {
  outline: none;
}
.numInp::-webkit-outer-spin-button,
.numInp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numInp[type="number"] {
  -moz-appearance: textfield;
}
.numInp > option,
.numInp > optgroup {
  background: var(--table-numinp-option-bg);
}

.mega-filter,
.send-fleet-block {
  max-height: 0;
  overflow: hidden;
  /* transition: max-height 0.1s ease; */
}

.send-fleet-block.opened,
.mega-filter.opened {
  max-height: 100%;
}

.mega-filter .numInp[type="text"] {
  padding: 0px 2px;
}

.mega-filter button,
.send-fleet-block button {
  background: var(--table-megafilter-button-bg);
  cursor: pointer;
  border: 0;
  color: var(--table-megafilter-button-color);
  padding: 2px 5px;
  display: inline-block;
}


.mega-filter button:hover,
.send-fleet-block button:hover {
  background: var(--table-megafilter-button-hover-bg);
}

.mega-filter a {
  border-bottom: 1px dashed var(--table-megafilter-a-border-color);
  cursor: pointer;
  user-select: none;
}

.mega-filter a:hover {
  border-bottom: none;
}

.tablePlanet_tbody tr {
  opacity: 1;
}

.tablePlanet_tbody tr:hover td {
    background: var(--table-tr-td-bg);
    cursor: pointer;
}

.tablePlanet_tbody tr td {
  user-select: none;
}

.tablePlanet_tbody tr.selected td {
    background: var(--table-tr-selected-bg);
    
}

.tablePlanet_tbody tr.singleselect td {
  user-select: auto;
}

.tablePlanet_tbody td.column-player-alliance span,
.tablePlanet_tbody td.column-player-name span {
  width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}

.mega-filter-opener {
  padding: 5px;
  /* height: 30px; */
  overflow: hidden;
  background: var(--table-megafilter-opener-bg);
  position: relative;
}

.mega-filter-opener a {
  cursor: pointer;
  user-select: none;
}

.mega-filter-opener .cond span {
  cursor: pointer;
}


.mega-filter-opener .cond {
  display: inline-block;
  color: var(--table-megafilter-cond-color);
  font-size: 10px;
  background: var(--table-megafilter-cond-bg);
  margin: 0 3px 0 3px;
  padding: 1px;
  border-radius: 2px;
}

.mega-filter h5 {
  font-size: 14px;
}

.mega-filter fieldset {
  border: 1px solid var(--table-megafilter-fieldset-border);
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.mega-filter legend {
  font-size: 14px;
  width: auto;
  margin: 0;
  padding: 0 5px;
  font-weight: normal;
}

.saved-filter button {
  font-size: 14px;
  padding: 0px 5px
}

.send-fleet-block table {
  width: 100%;
  border-color: var(--table-send-fleet-block-table-border-color);
  color: var(--table-send-fleet-block-table-border-color);
  font-size: 12px;
}

.send-fleet-block table td {
  padding: 0 !important;
  text-align: center;
}

.send-fleet-block table input {
  width: 100%;
  max-width: 100%;
  border: 0;
  text-align: center;
}


td.full button {
  width: 100%;
}

.loading-image-wrapper {
  position: relative;
  text-align: left;
}

.loading-image-wrapper img {
  position: sticky;
  left: 50%
}



.connection-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  vertical-align: middle;

}

.connection-indicator-text {
  color: var(--table-connection-indicator-text-color);
  font-size: 14px;
}

.connection-indicator-wrapper {
  display: inline-block;
  margin-right: 10px;
}

.mega-filter-opener span.maincolor {
  color: var(--table-mega-filter-opener-main-text-color);
}

[data-theme="light"] .loading-image-wrapper img {
  filter: invert(0.7);
}
</style>
